/* LOADER*/
.fishLoader {
  position: absolute;
  top: Calc(50% - 35vh);
  left: Calc(50% - 25vh);
}

.fishBodyLoader {
  position: absolute;
  width: 12vh;
  top: 27vh;
  left: 20vh;
}

/* tail */

.tailLoaderC {
  position: absolute;
  width: 8.5vh;
  top: 29vh;
  left: 14.5vh;
}
.tailLoaderN {
  position: absolute;
  width: 6vh;
  top: 29.2vh;
  left: 15.8vh;
}
.tailLoaderS {
  position: absolute;
  width: 4.5vh;
  top: 29vh;
  left: 17.4vh;
}
.tailLoaderCS {
  position: absolute;
  width: 4.5vh;
  top: 29.5vh;
  left: 16.8vh;
}
.tailLoaderA {
  position: absolute;
  width: 5.8vh;
  top: 29vh;
  left: 15.8vh;
}

/* spikes */
.spikesLoaderC {
  position: absolute;
  width: 12.6vh;
  left: 19.4vh;
  top: 26vh;
}
.spikesLoaderN {
  position: absolute;
  width: 14.2vh;
  left: 18.4vh;
  top: 26vh;
}

.spikesLoaderS {
  position: absolute;
  width: 11.5vh;
  left: 20vh;
  top: 27.5vh;
}

.spikesLoaderCS {
  position: absolute;
  width: 3.5vh;
  left: 21vh;
  top: 30.5vh;
}

.spikesLoaderA {
  position: absolute;
  width: 12.6vh;
  left: 19vh;
  top: 25.5vh;
}

/* top */
.topLoaderN {
  position: absolute;
  width: 7vh;
  left: 21.8vh;
  top: 25vh;
}
.topLoaderS {
  position: absolute;
  width: 8.5vh;
  left: 20.5vh;
  top: 24.8vh;
}
.topLoaderC {
  position: absolute;
  width: 6.7vh;
  left: 21.8vh;
  top: 25vh;
}

.topLoaderCS {
  position: absolute;
  width: 8vh;
  left: 21.8vh;
  top: 24.9vh;
}
.topLoaderA {
  position: absolute;
  width: 6.7vh;
  left: 21.8vh;
  top: 24.9vh;
}

/* fins */
.finsLoaderC {
  position: absolute;
  width: 7vh;
  left: 22vh;
  top: 35vh;
}
.finsLoaderS {
  position: absolute;
  width: 7vh;
  left: 21.8vh;
  top: 34.8vh;
}
.finsLoaderN {
  position: absolute;
  width: 7vh;
  left: 22vh;
  top: 36.1vh;
}
.finsLoaderCS {
  position: absolute;
  width: 6.8vh;
  left: 22.1vh;
  top: 36.2vh;
}
.finsLoaderA {
  position: absolute;
  width: 7vh;
  left: 22vh;
  top: 36.1vh;
}

/* eyes */
.eyesLoaderC {
  position: absolute;
  width: 7.2vh;
  top: 29vh;
  left: 24vh;
}

.eyesLoaderS {
  position: absolute;
  width: 11vh;
  top: 28.3vh;
  left: 20.5vh;
}

.eyesLoaderN {
  position: absolute;
  width: 7.1vh;
  top: 28.8vh;
  left: 24vh;
}
.eyesLoaderCS {
  position: absolute;
  width: 8vh;
  top: 29.4vh;
  left: 23.5vh;
}

.eyesLoaderA {
  position: absolute;
  width: 7.2vh;
  top: 29vh;
  left: 24vh;
}

/* mouth */
.mouthLoaderC {
  position: absolute;
  width: 3.3vh;
  top: 33.3vh;
  left: 27.5vh;
}
.mouthLoaderS {
  position: absolute;
  width: 3.3vh;
  top: 34.3vh;
  left: 27.2vh;
}
.mouthLoaderN {
  position: absolute;
  width: 5.5vh;
  top: 30vh;
  left: 25.5vh;
}
.mouthLoaderCS {
  position: absolute;
  width: 3.7vh;
  top: 34.4vh;
  left: 27.3vh;
}
.mouthLoaderA {
  position: absolute;
  width: 3.3vh;
  top: 33.7vh;
  left: 27.6vh;
}
