/* Screenshot */
.fishScreenshot {
  position: relative;
  top: Calc(50% - 35vh);
  left: Calc(50% - 25vh);
}

.fishBodyScreenshot {
  position: absolute;
  width: 12vh;
  top: 27vh;
  left: 20vh;
}

/* tail */

.tailScreenshotC {
  position: absolute;
  width: 8.5vh;
  top: 29vh;
  left: 14.5vh;
}
.tailScreenshotN {
  position: absolute;
  width: 6vh;
  top: 29.2vh;
  left: 15.8vh;
}
.tailScreenshotS {
  position: absolute;
  width: 4.5vh;
  top: 29vh;
  left: 17.4vh;
}
.tailScreenshotCS {
  position: absolute;
  width: 4.5vh;
  top: 29.5vh;
  left: 16.8vh;
}
.tailScreenshotA {
  position: absolute;
  width: 5.8vh;
  top: 29vh;
  left: 15.8vh;
}

/* spikes */
.spikesScreenshotC {
  position: absolute;
  width: 12.6vh;
  left: 19.4vh;
  top: 26vh;
}
.spikesScreenshotN {
  position: absolute;
  width: 14.2vh;
  left: 18.4vh;
  top: 26vh;
}

.spikesScreenshotS {
  position: absolute;
  width: 11.5vh;
  left: 20vh;
  top: 27.5vh;
}

.spikesScreenshotCS {
  position: absolute;
  width: 3.5vh;
  left: 21vh;
  top: 30.5vh;
}

.spikesScreenshotA {
  position: absolute;
  width: 12.6vh;
  left: 19vh;
  top: 25.5vh;
}

/* top */
.topScreenshotN {
  position: absolute;
  width: 7vh;
  left: 21.8vh;
  top: 25vh;
}
.topScreenshotS {
  position: absolute;
  width: 8.5vh;
  left: 20.5vh;
  top: 24.8vh;
}
.topScreenshotC {
  position: absolute;
  width: 6.7vh;
  left: 21.8vh;
  top: 25vh;
}

.topScreenshotCS {
  position: absolute;
  width: 8vh;
  left: 21.8vh;
  top: 24.9vh;
}
.topScreenshotA {
  position: absolute;
  width: 6.7vh;
  left: 21.8vh;
  top: 24.9vh;
}

/* fins */
.finsScreenshotC {
  position: absolute;
  width: 7vh;
  left: 22vh;
  top: 35vh;
}
.finsScreenshotS {
  position: absolute;
  width: 7vh;
  left: 21.8vh;
  top: 34.8vh;
}
.finsScreenshotN {
  position: absolute;
  width: 7vh;
  left: 22vh;
  top: 36.1vh;
}
.finsScreenshotCS {
  position: absolute;
  width: 6.8vh;
  left: 22.1vh;
  top: 36.2vh;
}
.finsScreenshotA {
  position: absolute;
  width: 7vh;
  left: 22vh;
  top: 36.1vh;
}

/* eyes */
.eyesScreenshotC {
  position: absolute;
  width: 7.2vh;
  top: 29vh;
  left: 24vh;
}

.eyesScreenshotS {
  position: absolute;
  width: 11vh;
  top: 28.3vh;
  left: 20.5vh;
}

.eyesScreenshotN {
  position: absolute;
  width: 7.1vh;
  top: 28.8vh;
  left: 24vh;
}
.eyesScreenshotCS {
  position: absolute;
  width: 8vh;
  top: 29.4vh;
  left: 23.5vh;
}

.eyesScreenshotA {
  position: absolute;
  width: 7.2vh;
  top: 29vh;
  left: 24vh;
}

/* mouth */
.mouthScreenshotC {
  position: absolute;
  width: 3.3vh;
  top: 33.3vh;
  left: 27.5vh;
}
.mouthScreenshotS {
  position: absolute;
  width: 3.3vh;
  top: 34.3vh;
  left: 27.2vh;
}
.mouthScreenshotN {
  position: absolute;
  width: 5.5vh;
  top: 30vh;
  left: 25.5vh;
}
.mouthScreenshotCS {
  position: absolute;
  width: 3.7vh;
  top: 34.4vh;
  left: 27.3vh;
}
.mouthScreenshotA {
  position: absolute;
  width: 3.3vh;
  top: 33.7vh;
  left: 27.6vh;
}
