.MuiTableCell-root{
  border-bottom: none !important;
  }
.stickyHeader{
  background-color: none !important;
}
.MuiCardContent-root{
  padding: 16px 16px 5px 16px !important;
}
.MuiCardActions-root{
  display: flex;
  padding: 0 8px 8px 8px !important;
  align-items: center;
}
