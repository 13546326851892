.clock{
    position: static;
    color: #A2A2A2;
    font-size: 20px;
    font-weight: bold;
    margin: auto;
    padding-bottom:15px;
    left: Calc(50% - 58.5px);
}
@media only screen and (min-width: 960px) {

    .clock{
        color: #A2A2A2;
        font-size: 32px;
        margin: auto;
        position: absolute;
        left: Calc(50% - 58.5px);
    }
  }