speechBubble {
  background-position: center;
  background-repeat: no-repeat !important;
  background-size: 100% 100%;
  margin: 0 auto;
  text-align: center;
  height: 0;
  box-sizing: content-box;
  line-height: 1;
  background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/4273/speech-bubble.svg);
  width: 25%;
  padding-top: 6%;
  padding-bottom: 20%;
}

.isDead {
  position: absolute;
  width: 6vh;
  top: 8.5vh;
  left: 9.5vh;
}

/* FISH TANK */

.fish {
  position: absolute;
  left: calc(50% - 70px);
}

.fishBody {
  position: absolute;
  width: 12.5vh;
  top: 6vh;
  left: 5vh;
}

/* tail */

.tailC {
  position: absolute;
  width: 9vh;
  top: 8.5vh;
  left: -0.8vh;
}

.tailS {
  position: absolute;
  width: 5vh;
  top: 8.1vh;
  left: 1.6vh;
}

.tailN {
  position: absolute;
  width: 6vh;
  top: 9vh;
  left: 0.8vh;
}

.tailCS {
  position: absolute;
  width: 5.2vh;
  top: 7.5vh;
  left: 1.2vh;
}

.tailA {
  position: absolute;
  width: 6vh;
  top: 7.5vh;
  left: 0vh;
}

/* spikes */

.spikesC {
  position: absolute;
  width: 13.2vh;
  left: 4.3vh;
  top: 5vh;
}

.spikesN {
  position: absolute;
  width: 14.6vh;
  left: 3.5vh;
  top: 5vh;
}

.spikesS {
  position: absolute;
  width: 11.5vh;
  left: 5.2vh;
  top: 6.8vh;
}
.spikesCS {
  position: absolute;
  width: 3.2vh;
  left: 5.8vh;
  top: 10vh;
}

.spikesA {
  position: absolute;
  width: 13vh;
  left: 4.2vh;
  top: 4.7vh;
}

/* top */

.topS {
  position: absolute;
  width: 8vh;
  left: 5.5vh;
  top: 3.9vh;
}

.topN {
  position: absolute;
  width: 6.9vh;
  left: 6.9vh;
  top: 3.7vh;
}

.topC {
  position: absolute;
  width: 7vh;
  left: 7vh;
  top: 4.1vh;
}

.topCS {
  position: absolute;
  width: 8.8vh;
  left: 6.7vh;
  top: 3.8vh;
}

.topA {
  position: absolute;
  width: 7vh;
  left: 6.8vh;
  top: 4.1vh;
}

/* fins */

.finsC {
  position: absolute;
  width: 7vh;
  left: 7.4vh;
  top: 14.5vh;
}
.finsS {
  position: absolute;
  width: 7vh;
  left: 7vh;
  top: 14.2vh;
}
.finsN {
  position: absolute;
  width: 6vh;
  left: 7.8vh;
  top: 16vh;
}
.finsCS {
  position: absolute;
  width: 7vh;
  left: 7vh;
  top: 15.8vh;
}
.finsA {
  position: absolute;
  width: 7vh;
  left: 7.2vh;
  top: 15.5vh;
}

/* eyes */

.eyesC {
  position: absolute;
  width: 7.5vh;
  top: 8.2vh;
  left: 9.2vh;
}
.eyesN {
  position: absolute;
  width: 7vh;
  top: 8.4vh;
  left: 9.7vh;
}
.eyesS {
  position: absolute;
  width: 10.9vh;
  top: 7.1vh;
  left: 5.8vh;
}
.eyesCS {
  position: absolute;
  width: 8.7vh;
  top: 8.4vh;
  left: 8.6vh;
}
.eyesA {
  position: absolute;
  width: 7.5vh;
  top: 8.4vh;
  left: 9.2vh;
}

/* mouth */

.mouthC {
  position: absolute;
  width: 3.4vh;
  top: 13vh;
  left: 12.8vh;
}

.mouthN {
  position: absolute;
  width: 5.6vh;
  left: 10.8vh;
  top: 9.2vh;
}

.mouthS {
  position: absolute;
  width: 3.8vh;
  top: 13.4vh;
  left: 12.5vh;
}

.mouthCS {
  position: absolute;
  width: 3.7vh;
  left: 12.9vh;
  top: 13.8vh;
}

.mouthA {
  position: absolute;
  width: 3.5vh;
  top: 13vh;
  left: 12.9vh;
}

/* pimple */

.mouthPimple {
  position: absolute;
  width: 6vh;
  top: 8vh;
  left: 12vh;
}
@media (min-width: 2400px) and (max-width: 3500px) {
  /* FISH TANK */

  .fishBody {
    position: absolute;
    width: 8vh !important;
    top: 3vh !important;
    left: 1vh !important;
  }

  /* tail */

  .tailC {
    position: absolute;
    width: 5.5vh !important;
    top: 4vh !important;
    left: -2.3vh !important;
  }

  .tailS {
    position: absolute !important;
    width: 3vh !important;
    top: 4.2vh !important;
    left: -1vh !important;
  }

  .tailN {
    position: absolute !important;
    width: 3.8vh !important;
    top: 4vh !important;
    left: -1.5vh !important;
  }

  .tailA {
    position: absolute !important;
    width: 4.3vh !important;
    top: 4.3vh !important;
    left: -2vh !important;
  }

  .tailCS {
    position: absolute !important;
    width: 3.5vh !important;
    top: 3.9vh !important;
    left: -1.2vh !important;
  }

  /* spikes */

  .spikesC {
    position: absolute !important;
    width: 8.2vh !important;
    left: 0.7vh !important;
    top: 2.7vh !important;
  }

  .spikesN {
    position: absolute !important;
    width: 9vh !important;
    left: 0.1vh !important;
    top: 2.6vh !important;
  }

  .spikesS {
    position: absolute !important;
    width: 7.5vh !important;
    left: 1.1vh !important;
    top: 3.3vh !important;
  }
  .spikesA {
    position: absolute !important;
    width: 8.3vh !important;
    left: 0.4vh !important;
    top: 2.1vh !important;
  }

  .spikesCS {
    position: absolute !important;
    width: 2.5vh !important;
    left: 1.8vh !important;
    top: 5vh !important;
  }

  /* top */

  .topS {
    position: absolute !important;
    width: 5vh !important;
    left: 2vh !important;
    top: 1.8vh !important;
  }

  .topN {
    position: absolute !important;
    width: 5vh !important;
    left: 2vh !important;
    top: 1.5vh !important;
  }

  .topC {
    position: absolute !important;
    width: 4.5vh !important;
    left: 2.5vh !important;
    top: 1.9vh !important;
  }
  .topA {
    position: absolute !important;
    width: 5vh !important;
    left: 1.8vh !important;
    top: 1.7vh !important;
  }

  .topCS {
    position: absolute !important;
    width: 5.5vh !important;
    left: 2vh !important;
    top: 1.9vh !important;
  }

  /* fins */

  .finsC {
    position: absolute !important;
    width: 4vh !important;
    left: 3vh !important;
    top: 8.5vh !important;
  }
  .finsS {
    position: absolute !important;
    width: 4.6vh !important;
    left: 2.54vh !important;
    top: 8.2vh !important;
  }
  .finsN {
    position: absolute !important;
    width: 4vh !important;
    left: 3vh !important;
    top: 9.2vh !important;
  }
  .finsCS {
    position: absolute !important;
    width: 5vh !important;
    left: 2.4vh !important;
    top: 9vh !important;
  }
  .finsA {
    position: absolute !important;
    width: 4.4vh !important;
    left: 2.8vh !important;
    top: 9vh !important;
  }

  /* eyes */

  .eyesC {
    position: absolute !important;
    width: 4.4vh !important;
    top: 4.4vh !important;
    left: 4.1vh !important;
  }
  .eyesN {
    position: absolute !important;
    width: 4.3vh !important;
    top: 4.4vh !important;
    left: 4.1vh !important;
  }
  .eyesS {
    position: absolute !important;
    width: 7vh !important;
    top: 3.7vh !important;
    left: 1.5vh !important;
  }
  .eyesA {
    position: absolute !important;
    width: 4.8vh !important;
    top: 4.3vh !important;
    left: 3.7vh !important;
  }
  .eyesCS {
    position: absolute !important;
    width: 5vh !important;
    top: 4.6vh !important;
    left: 3.6vh !important;
  }

  .isDead {
    position: absolute !important;
    width: 3vh !important;
    top: 5vh !important;
    left: 4.2vh !important;
  }

  /* mouth */

  .mouthC {
    position: absolute !important;
    width: 2.2vh !important;
    top: 7.1vh !important;
    left: 6vh !important;
  }

  .mouthN {
    position: absolute !important;
    width: 3.5vh !important;
    top: 5.1vh !important;
    left: 4.8vh !important;
  }

  .mouthS {
    position: absolute !important;
    width: 2.2vh !important;
    top: 8vh !important;
    left: 5.9vh !important;
  }
  .mouthCS {
    position: absolute !important;
    width: 2.5vh !important;
    top: 7.8vh !important;
    left: 5.9vh !important;
  }
  .mouthA {
    position: absolute !important;
    width: 2.1vh !important;
    top: 7.6vh !important;
    left: 6.1vh !important;
  }

  /* pimple */

  .mouthPimple {
    position: absolute !important;
    width: 6vh !important;
    top: 8vh !important;
    left: 12vh !important;
  }
}

@media (min-width: 3500px) and (max-width: 9000px) {
  /* FISH TANK */
  .fishBody {
    position: absolute !important;
    width: 4.5vh !important;
    top: 1.5vh !important;
    left: 0.2vh !important;
  }

  /* tail */

  .tailC {
    position: absolute;
    width: 3vh !important;
    top: 2.5vh !important;
    left: -1.8vh !important;
  }

  .tailS {
    position: absolute;
    width: 1.5vh !important;
    top: 2.5vh !important;
    left: -1vh !important;
  }

  .tailN {
    position: absolute;
    width: 2.2vh !important;
    top: 2.5vh !important;
    left: -1.2vh !important;
  }

  .tailCS {
    position: absolute;
    width: 1.9vh !important;
    top: 2vh !important;
    left: -1vh !important;
  }

  .tailA {
    position: absolute;
    width: 2.2vh !important;
    top: 2.2vh !important;
    left: -1.5vh !important;
  }

  /* spikes */

  .spikesC {
    position: absolute;
    width: 4.7vh !important;
    left: 0vh !important;
    top: 1.2vh !important;
  }

  .spikesN {
    position: absolute;
    width: 5vh !important;
    left: -0.2vh !important;
    top: 1.3vh !important;
  }

  .spikesS {
    position: absolute;
    width: 4.3vh !important;
    left: 0.2vh !important;
    top: 1.6vh !important;
  }

  .spikesA {
    position: absolute;
    width: 4.5vh !important;
    left: 0vh !important;
    top: 1.2vh !important;
  }

  .spikesCS {
    position: absolute;
    width: 1.5vh !important;
    left: 0.5vh !important;
    top: 2.9vh !important;
  }

  /* top */

  .topS {
    position: absolute;
    width: 2.4vh !important;
    left: 0.8vh !important;
    top: 0.9vh !important;
  }

  .topN {
    position: absolute;
    width: 2.1vh !important;
    left: 1.2vh !important;
    top: 0.9vh !important;
  }

  .topC {
    position: absolute;
    width: 2.6vh !important;
    left: 0.9vh !important;
    top: 0.8vh !important;
  }
  .topCS {
    position: absolute;
    width: 2.9vh !important;
    top: 1vh !important;
    left: 1.2vh !important;
  }

  .topA {
    position: absolute;
    width: 2.6vh !important;
    left: 0.9vh !important;
    top: 0.8vh !important;
  }

  /* fins */

  .finsC {
    position: absolute;
    width: 2.8vh !important;
    left: 1vh !important;
    top: 4.4vh !important;
  }
  .finsS {
    position: absolute;
    width: 2.5vh !important;
    left: 1.1vh !important;
    top: 4.4vh !important;
  }
  .finsN {
    position: absolute;
    width: 2.5vh !important;
    left: 1.1vh !important;
    top: 5vh !important;
  }
  .finsCS {
    position: absolute;
    width: 2.8vh !important;
    top: 4.8vh !important;
    left: 1vh !important;
  }
  .finsA {
    position: absolute;
    width: 2.5vh !important;
    left: 1.1vh !important;
    top: 5vh !important;
  }

  /* eyes */

  .eyesC {
    position: absolute;
    width: 2.5vh !important;
    top: 2.3vh !important;
    left: 2vh !important;
  }
  .eyesN {
    position: absolute;
    width: 2.5vh !important;
    top: 2.2vh !important;
    left: 1.9vh !important;
  }
  .eyesS {
    position: absolute;
    width: 3.8vh !important;
    top: 1.9vh !important;
    left: 0.5vh !important;
  }
  .eyesCS {
    position: absolute;
    width: 2.8vh !important;
    top: 2.4vh !important;
    left: 1.7vh !important;
  }
  .eyesA {
    position: absolute;
    width: 2.5vh !important;
    top: 2.3vh !important;
    left: 2vh !important;
  }

  .isDead {
    position: absolute;
    width: 2.2vh !important;
    top: 2.5vh !important;
    left: 1.5vh !important;
  }

  /* mouth */

  .mouthC {
    position: absolute;
    width: 1.1vh !important;
    top: 4vh !important;
    left: 3.2vh !important;
  }

  .mouthN {
    position: absolute;
    width: 1.7vh !important;
    top: 2.7vh !important;
    left: 2.6vh !important;
  }

  .mouthS {
    position: absolute;
    width: 1.2vh !important;
    top: 4.1vh !important;
    left: 3vh !important;
  }
  .mouthCS {
    position: absolute;
    width: 1.3vh !important;
    top: 4.2vh !important;
    left: 3vh !important;
  }

  .mouthA {
    position: absolute;
    width: 1.2vh !important;
    top: 4.1vh !important;
    left: 3.1vh !important;
  }
}

@media (min-width: 2400px) and (max-width: 3000px) {
  .fish {
    position: absolute;
    /* top: calc(50% - 60px) !important; */
    left: calc(50% - 60px) !important;
  }
}

@media (min-width: 3500px) and (max-width: 4300px) {
  .fish {
    position: absolute;
    /* top: calc(50% - 50px) !important; */
    left: calc(50% - 50px) !important;
  }
}
