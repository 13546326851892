/* Screenshot1000*/
.fishScreenshot1000 {
  position: absolute;
  top: Calc(50% - 300px);
  left: Calc(50% - 200px);
}

.fishBodyScreenshot1000 {
  position: absolute;
  width: 450px;
  top: 50px;
}

.tailScreenshot1000C {
  position: absolute;
  width: 300px;
  top: 159px;
  left: -190px;
}
.tailScreenshot1000N {
  position: absolute;
  width: 225px;
  top: 124px;
  left: -180px;
}
.tailScreenshot1000S {
  position: absolute;
  width: 172px;
  top: 124px;
  left: -128px;
}
.tailScreenshot1000CS {
  position: absolute;
  width: 189px;
  top: 117px;
  left: -155px;
}
.tailScreenshot1000A {
  position: absolute;
  width: 225px;
  top: 100px;
  left: -180px;
}

/* spikes */
.spikesScreenshot1000C {
  position: absolute;
  width: 479px;
  top: 16px;
  left: -28px;
}
.spikesScreenshot1000N {
  position: absolute;
  width: 537px;
  top: 1px;
  left: -67px;
}

.spikesScreenshot1000S {
  position: absolute;
  width: 414px;
  top: 75px;
  left: 6px;
}

.spikesScreenshot1000CS {
  position: absolute;
  width: 111px;
  top: 208px;
  left: 38px;
}

.spikesScreenshot1000A {
  position: absolute;
  width: 462px;
  top: 9px;
  left: -25px;
}

/* top */
.topScreenshot1000N {
  position: absolute;
  width: 298px;
  left: 48px;
  top: -42px;
}
.topScreenshot1000S {
  position: absolute;
  width: 296px;
  left: 19px;
  top: -29px;
}
.topScreenshot1000C {
  position: absolute;
  width: 289px;
  left: 46px;
  top: -29px;
}

.topScreenshot1000CS {
  position: absolute;
  width: 363px;
  left: 29px;
  top: -34px;
}
.topScreenshot1000A {
  position: absolute;
  width: 280px;
  left: 45px;
  top: -26px;
}

/* fins */
.finsScreenshot1000C {
  position: absolute;
  width: 229px;
  left: 82px;
  top: 366px;
}
.finsScreenshot1000S {
  position: absolute;
  width: 250px;
  left: 48px;
  top: 356px;
}
.finsScreenshot1000N {
  position: absolute;
  width: 250px;
  left: 69px;
  top: 396px;
}
.finsScreenshot1000CS {
  position: absolute;
  width: 250px;
  left: 69px;
  top: 396px;
}
.finsScreenshot1000A {
  position: absolute;
  width: 248px;
  left: 92px;
  top: 387px;
}

/* eyes */
.eyesScreenshot1000C {
  position: absolute;
  width: 273px;
  top: 131px;
  left: 151px;
}

.eyesScreenshot1000S {
  position: absolute;
  width: 392px;
  top: 96px;
  left: 27px;
}

.eyesScreenshot1000N {
  position: absolute;
  width: 271px;
  top: 120px;
  left: 141px;
}
.eyesScreenshot1000CS {
  position: absolute;
  width: 323px;
  top: 130px;
  left: 116px;
}

.eyesScreenshot1000A {
  position: absolute;
  width: 269px;
  top: 135px;
  left: 157px;
}

/* mouth */
.mouthScreenshot1000C {
  position: absolute;
  width: 128px;
  top: 302px;
  left: 274px;
}
.mouthScreenshot1000S {
  position: absolute;
  width: 130px;
  top: 331px;
  left: 266px;
}
.mouthScreenshot1000N {
  position: absolute;
  width: 187px;
  top: 186px;
  left: 224px;
}
.mouthScreenshot1000CS {
  position: absolute;
  width: 153px;
  top: 330px;
  left: 263px;
}
.mouthScreenshot1000A {
  position: absolute;
  width: 123px;
  top: 319px;
  left: 285px;
}
