/* card styles */
.fishCard {
  position: relative;
  left: Calc(50% - 14vh);
}

.fishBodyCard {
  position: absolute;
  width: 8vh;
  top: 2vh;
  left: 10vh;
}
/* tails */
.tailCardC {
  position: absolute;
  width: 6vh;
  top: 3vh;
  left: 6.1vh;
}
.tailCardS {
  position: absolute;
  width: 3vh;
  top: 3vh;
  left: 8vh;
}
.tailCardN {
  position: absolute;
  width: 3.8vh;
  top: 3.8vh;
  left: 7.5vh;
}
.tailCardA {
  position: absolute;
  width: 4vh;
  top: 3vh;
  left: 6.6vh;
}
.tailCardCS {
  position: absolute;
  width: 3.1vh;
  top: 3vh;
  left: 8vh;
}
/* spikes */

.spikesCardC {
  position: absolute;
  width: 8.5vh;
  top: 1.4vh;
  left: 9.5vh;
}
.spikesCardN {
  position: absolute;
  width: 9.4vh;
  top: 1.4vh;
  left: 9vh;
}
.spikesCardS {
  position: absolute;
  width: 7.4vh;
  top: 2.4vh;
  left: 10.1vh;
}
.spikesCardA {
  position: absolute;
  width: 8.3vh;
  top: 1.2vh;
  left: 9.6vh;
}
.spikesCardCS {
  position: absolute;
  width: 2.1vh;
  top: 4.5vh;
  left: 10.5vh;
}

/* top */
.topCardC {
  position: absolute;
  width: 5vh;
  top: 0.5vh;
  left: 11vh;
}
.topCardN {
  position: absolute;
  width: 4.5vh;
  top: 0.6vh;
  left: 11.5vh;
}

.topCardS {
  position: absolute;
  width: 5vh;
  top: 0.7vh;
  left: 10.6vh;
}
.topCardCS {
  position: absolute;
  width: 5vh;
  top: 0.6vh;
  left: 11.5vh;
}
.topCardA {
  position: absolute;
  width: 4.4vh;
  top: 0.9vh;
  left: 11.2vh;
}
/* fins */
.finsCardC {
  position: absolute;
  width: 4.5vh;
  top: 7.3vh;
  left: 11.7vh;
}
.finsCardN {
  position: absolute;
  width: 3.6vh;
  top: 8.4vh;
  left: 12vh;
}
.finsCardS {
  position: absolute;
  width: 3.7vh;
  top: 7.7vh;
  left: 11.7vh;
}
.finsCardCS {
  position: absolute;
  width: 4.5vh;
  top: 8vh;
  left: 11.7vh;
}
.finsCardA {
  position: absolute;
  width: 4.5vh;
  top: 7.9vh;
  left: 11.7vh;
}

/* eyes */
.eyesCardC {
  position: absolute;
  width: 4.8vh;
  top: 3.5vh;
  left: 12.8vh;
}
.eyesCardN {
  position: absolute;
  width: 4.5vh;
  top: 3.4vh;
  left: 13vh;
}
.eyesCardS {
  position: absolute;
  width: 7.3vh;
  top: 2.8vh;
  left: 10.3vh;
}
.eyesCardA {
  position: absolute;
  width: 4.8vh;
  top: 3.5vh;
  left: 12.8vh;
}
.eyesCardCS {
  position: absolute;
  width: 5.3vh;
  top: 3.7vh;
  left: 12.5vh;
}

/* mouth */
.mouthCardC {
  position: absolute;
  width: 2vh;
  top: 6.6vh;
  left: 15.1vh;
}
.mouthCardS {
  position: absolute;
  width: 2.2vh;
  top: 7vh;
  left: 14.8vh;
}
.mouthCardN {
  position: absolute;
  width: 3.5vh;
  top: 4.2vh;
  left: 13.8vh;
}
.mouthCardCS {
  position: absolute;
  width: 2.4vh;
  top: 6.9vh;
  left: 15vh;
}
.mouthCardA {
  position: absolute;
  width: 2.2vh;
  top: 6.7vh;
  left: 15vh;
}

/* pimple */
.mouthCardPimple {
  position: absolute;
  width: 2vh;
  top: 3.3vh;
  left: 15.6vh;
}
/* card end */

@media (min-width: 1800px) and (max-width: 3500px) {
  .fishBodyCard {
    position: absolute !important;
    width: 5vh !important;
    top: 1vh !important;
    left: 12vh !important;
  }
  /* TAIL  */
  .tailCardC {
    position: absolute !important;
    width: 3.5vh !important;
    top: 2.2vh !important;
    left: 9.8vh !important;
  }
  .tailCardN {
    position: absolute !important;
    width: 2.5vh !important;
    top: 2vh !important;
    left: 10.3vh !important;
  }
  .tailCardS {
    position: absolute !important;
    width: 1.9vh !important;
    top: 1.9vh !important;
    left: 10.5vh !important;
  }
  .tailCardA {
    position: absolute !important;
    width: 2.5vh !important;
    top: 1.6vh !important;
    left: 10vh !important;
  }
  .tailCardCS {
    position: absolute !important;
    width: 2vh !important;
    top: 1.5vh !important;
    left: 10.7vh !important;
  }

  /* SPIKES */
  .spikesCardC {
    position: absolute !important;
    width: 5.3vh !important;
    top: 0.6vh !important;
    left: 11.7vh !important;
  }
  .spikesCardS {
    position: absolute !important;
    width: 4.7vh !important;
    top: 1.1vh !important;
    left: 12vh !important;
  }
  .spikesCardN {
    position: absolute !important;
    width: 5.8vh !important;
    top: 0.6vh !important;
    left: 11.3vh !important;
  }
  .spikesCardA {
    position: absolute !important;
    width: 5.2vh !important;
    top: 0.4vh !important;
    left: 11.6vh !important;
  }
  .spikesCardCS {
    position: absolute !important;
    width: 1.5vh !important;
    top: 2.3vh !important;
    left: 12.2vh !important;
  }
  /* TOP */

  .topCardC {
    position: absolute !important;
    width: 2.5vh !important;
    top: 0.4vh !important;
    left: 13vh !important;
  }
  .topCardS {
    position: absolute !important;
    width: 2.8vh !important;
    top: 0.4vh !important;
    left: 12.8vh !important;
  }
  .topCardN {
    position: absolute !important;
    width: 2.5vh !important;
    top: 0.2vh !important;
    left: 13vh !important;
  }
  .topCardCS {
    position: absolute !important;
    width: 3.1vh !important;
    top: 0.2vh !important;
    left: 13vh !important;
  }
  .topCardA {
    position: absolute !important;
    width: 2.6vh !important;
    top: 0.3vh !important;
    left: 12.8vh !important;
  }

  /* FINS */
  .finsCardC {
    position: absolute !important;
    width: 2.6vh !important;
    top: 4.5vh !important;
    left: 13vh !important;
  }
  .finsCardS {
    position: absolute !important;
    width: 2.8vh !important;
    top: 4.3vh !important;
    left: 12.9vh !important;
  }
  .finsCardN {
    position: absolute !important;
    width: 2.5vh !important;
    top: 5vh !important;
    left: 13.1vh !important;
  }
  .finsCardA {
    position: absolute !important;
    width: 3.2vh !important;
    top: 4.5vh !important;
    left: 13vh !important;
  }
  .finsCardCS {
    position: absolute !important;
    width: 3.1vh !important;
    top: 4.7vh !important;
    left: 12.8vh !important;
  }

  /* EYES */
  .eyesCardC {
    position: absolute !important;
    width: 3vh !important;
    top: 1.7vh !important;
    left: 13.7vh !important;
  }
  .eyesCardN {
    position: absolute !important;
    width: 2.9vh !important;
    top: 1.8vh !important;
    left: 13.8vh !important;
  }
  .eyesCardS {
    position: absolute !important;
    width: 4.4vh !important;
    top: 1.5vh !important;
    left: 12.3vh !important;
  }
  .eyesCardA {
    position: absolute !important;
    width: 3vh !important;
    top: 1.9vh !important;
    left: 13.7vh !important;
  }
  .eyesCardCS {
    position: absolute !important;
    width: 3.2vh !important;
    top: 2.1vh !important;
    left: 13.7vh !important;
  }

  /* MOUTH */
  .mouthCardC {
    position: absolute !important;
    width: 1.4vh !important;
    top: 3.6vh !important;
    left: 15.2vh !important;
  }
  .mouthCardN {
    position: absolute !important;
    width: 2.3vh !important;
    top: 2.2vh !important;
    left: 14.2vh !important;
  }
  .mouthCardS {
    position: absolute !important;
    width: 1.2vh !important;
    top: 4.1vh !important;
    left: 15.1vh !important;
  }
  .mouthCardA {
    position: absolute !important;
    width: 1.4vh !important;
    top: 3.8vh !important;
    left: 15.2vh !important;
  }
  .mouthCardCS {
    position: absolute !important;
    width: 1.5vh !important;
    top: 4.1vh !important;
    left: 15vh !important;
  }

  /* PIMPLE */
  .mouthCardPimple {
    position: absolute !important;
    width: 1vh !important;
    top: 3.3vh !important;
    left: 15.6vh !important;
  }
}

@media (min-width: 3500px) and (max-width: 9000px) {
  .fishBodyCard {
    position: absolute !important;
    width: 2.8vh !important;
    top: 0.5vh !important;
    left: 13vh !important;
  }

  /* TAIL */

  .tailCardC {
    position: absolute !important;
    width: 2vh !important;
    top: 0.7vh !important;
    left: 11.7vh !important;
  }
  .tailCardS {
    position: absolute !important;
    width: 1.1vh !important;
    top: 0.8vh !important;
    left: 12.2vh !important;
  }
  .tailCardN {
    position: absolute !important;
    width: 1.3vh !important;
    top: 1vh !important;
    left: 12vh !important;
  }
  .tailCardCS {
    position: absolute !important;
    width: 1vh !important;
    top: 0.8vh !important;
    left: 12.3vh !important;
  }
  .tailCardA {
    position: absolute !important;
    width: 1.4vh !important;
    top: 0.8vh !important;
    left: 11.9vh !important;
  }

  /* SPIKES */

  .spikesCardC {
    position: absolute !important;
    width: 3.2vh !important;
    top: 0.3vh !important;
    left: 12.8vh !important;
  }
  .spikesCardS {
    position: absolute !important;
    width: 2.6vh !important;
    top: 0.6vh !important;
    left: 13.1vh !important;
  }
  .spikesCardN {
    position: absolute !important;
    width: 3.3vh !important;
    top: 0.2vh !important;
    left: 12.6vh !important;
  }
  .spikesCardCS {
    position: absolute !important;
    width: 0.8vh !important;
    top: 1.4vh !important;
    left: 13.2vh !important;
  }
  .spikesCardA {
    position: absolute !important;
    width: 2.9vh !important;
    top: 0.2vh !important;
    left: 12.8vh !important;
  }

  /* TOP */

  .topCardC {
    position: absolute !important;
    width: 2vh !important;
    top: 0vh !important;
    left: 13vh !important;
  }

  .topCardS {
    position: absolute !important;
    width: 1.9vh !important;
    top: 0.2vh !important;
    left: 13.3vh !important;
  }

  .topCardN {
    position: absolute !important;
    width: 1.5vh !important;
    top: 0.1vh !important;
    left: 13.5vh !important;
  }
  .topCardCS {
    position: absolute !important;
    width: 1.8vh !important;
    top: 0.1vh !important;
    left: 13.5vh !important;
  }
  .topCardA {
    position: absolute !important;
    width: 1.5vh !important;
    top: 0.1vh !important;
    left: 13.5vh !important;
  }

  /* FINS */

  .finsCardC {
    position: absolute !important;
    width: 1.5vh !important;
    top: 2.5vh !important;
    left: 13.5vh !important;
  }
  .finsCardN {
    position: absolute !important;
    width: 1.4vh !important;
    top: 2.7vh !important;
    left: 13.7vh !important;
  }

  .finsCardS {
    position: absolute !important;
    width: 1.4vh !important;
    top: 2.5vh !important;
    left: 13.6vh !important;
  }
  .finsCardCS {
    position: absolute !important;
    width: 1.5vh !important;
    top: 2.6vh !important;
    left: 13.6vh !important;
  }
  .finsCardA {
    position: absolute !important;
    width: 1.6vh !important;
    top: 2.5vh !important;
    left: 13.7vh !important;
  }

  /* EYES */

  .eyesCardC {
    position: absolute !important;
    width: 1.5vh !important;
    top: 0.9vh !important;
    left: 14vh !important;
  }

  .eyesCardS {
    position: absolute !important;
    width: 2.5vh !important;
    top: 0.8vh !important;
    left: 13.2vh !important;
  }

  .eyesCardN {
    position: absolute !important;
    width: 1.6vh !important;
    top: 1.1vh !important;
    left: 14.1vh !important;
  }
  .eyesCardCS {
    position: absolute !important;
    width: 2vh !important;
    top: 1.1vh !important;
    left: 13.8vh !important;
  }
  .eyesCardA {
    position: absolute !important;
    width: 1.7vh !important;
    top: 1vh !important;
    left: 14vh !important;
  }

  /* MOUTH */

  .mouthCardC {
    position: absolute !important;
    width: 0.8vh !important;
    top: 2.1vh !important;
    left: 14.6vh !important;
  }

  .mouthCardN {
    position: absolute !important;
    width: 1.1vh !important;
    top: 1.4vh !important;
    left: 14.5vh !important;
  }

  .mouthCardS {
    position: absolute !important;
    width: 0.8vh !important;
    top: 2.2vh !important;
    left: 14.8vh !important;
  }
  .mouthCardCS {
    position: absolute !important;
    width: 0.8vh !important;
    top: 2.3vh !important;
    left: 14.7vh !important;
  }
  .mouthCardA {
    position: absolute !important;
    width: 0.8vh !important;
    top: 2.1vh !important;
    left: 14.7vh !important;
  }

  /* PIMPLE */

  .mouthCardPimple {
    position: absolute !important;
    width: 1vh !important;
    top: 3.3vh !important;
    left: 15.6vh !important;
  }
}
