.MuiInput-underline:before {
    border-bottom: 0px white !important;
}
.MuiInput-underline:after {
    border-bottom: 0px white !important;
}
.MuiTypography-body2{
    font-size: 0.875rem;
}

@media (min-width:408px) and (max-width:470px) {
    .MuiTypography-body2{
        font-size: 12px !important;
    }
  }
  @media (min-width:960px) and (max-width:1107px){
    .MuiTypography-body2{
        font-size: 13px !important;
    }
  }
  .MuiSelect-select.MuiSelect-select {
  display: flex !important;
}

.MuiSelect-select:not([multiple]) option, .MuiSelect-select:not([multiple]) optgroup {
    background-color: none !important;
}
Option{
    background-color: inherit !important;
}