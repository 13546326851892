.speechBubble {
    position: absolute;
    font-family: 'Orbitron', sans-serif;
    background-position: center;
    background-repeat: no-repeat !important;
    background-size: 100% 100%;
    margin: 0 auto;
    text-align: center;
    height: 0;
    box-sizing: content-box;
   line-height:1;
    /* background:
      "url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/4273/speech-bubble.svg)", */
    width: 25%;
    padding-top: 6%;
    padding-bottom:20%;
  }