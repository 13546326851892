.padding {
  padding: 6px;
}
@media only screen and (max-width: 959px) {
  .activeTopbarItem {
    padding: 6px !important;
  }
  .topbarItem {
    padding: 6px !important;
  }
}
